<template>
  <loading v-if="loading" />

  <div v-else>
    <b-table
      :data="table.data"
      :mobile-cards="true"
      :default-sort="[table.sort.field, table.sort.direction]"
      :loading="table.loading"
      backend-sorting
      hoverable
      striped
      class="has-vertical-align-middle"
      @sort="onSort"
    >
      <template slot="empty">
        <no-results
          icon="file-invoice"
          title="No invoices"
          message="There are no invoices to show."
        />
      </template>

      <template slot-scope="props">
        <b-table-column label="Status" field="status" width="100" sortable>
          <status-label :status="props.row.status" />
        </b-table-column>

        <b-table-column label="Invoice #" field="invoiceNum" sortable>
          <a class="has-text-dark" @click="viewInvoice(props.row)">
            <u>{{ props.row.invoiceNum }}</u>
          </a>
        </b-table-column>

        <b-table-column label="Total" field="total" width="100" sortable>{{
          $formatCurrency(props.row.total, props.row.currency)
        }}</b-table-column>

        <b-table-column
          label="Date created"
          field="dateCreated"
          sortable
          width="180"
          >{{ $moment(props.row.dateCreated.toDate()).from() }}</b-table-column
        >
      </template>
    </b-table>

    <load-more
      v-if="!table.complete"
      :loading="table.loading"
      @loadMore="getData(true)"
    />
  </div>
</template>

<script>
import { collection, doc, query, where } from "@firebase/firestore";
export default {
  name: "InvoicesTable",
  mixins: [require("@mixins/table-with-filtering-and-sorting").default],
  props: {
    userId: {
      default: null,
      validator: value => {
        return ["string", "null"].includes(typeof value);
      }
    },
    contractId: {
      default: null,
      validator: value => {
        return ["string", "null"].includes(typeof value);
      }
    }
  },
  data() {
    return {
      loading: true,
      table: {
        limit: 5
      }
    };
  },
  methods: {
    getFirestoreRef() {
      let ref = collection(this.$firestore, "invoices");
      if (this.userId) {
        ref = query(ref, where("userId", "==", this.userId));
      }
      if (this.contractId) {
        let contractRef = doc(this.$firestore, "contracts", this.contractId);
        ref = query(ref, where("contractRef", "==", contractRef));
      }
      return this.buildFirestoreRef(ref);
    },
    afterDataLoad() {
      this.loading = false;
    },
    viewInvoice(invoice) {
      this.$modal.open({
        component: () => import("./_invoiceModal"),
        parent: this,
        width: 720,
        canCancel: ["escape", "outside"],
        hasModalCard: true,
        props: {
          invoice: invoice
        }
      });
    }
  }
};
</script>
